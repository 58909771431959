import React, { useState } from "react"
import loadable from 'loadable-components'
import { graphql } from "gatsby"
import { Controller, Scene } from 'react-scrollmagic'
import { useInView } from 'react-intersection-observer'

import Div100vh from 'react-div-100vh'
import Headtagmod from '../components/headtagmod'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Img from 'gatsby-image'

// import GooglemapView from '../components/module-googlemap'
// import FormToCompany from '../components/form-to-company'
// import Footer from '../components/footer-common'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faNetworkWired,
  faChartBar,
  faPallet,
  faServer,
  faFlagCheckered,
  faSatellite,
} from "@fortawesome/free-solid-svg-icons"

const BackgroundSlider = loadable(() => import('../components/toppage-background-images'))
const GooglemapView = loadable(() => import('../components/module-googlemap'))
const FormToCompany = loadable(() => import('../components/form-to-company'))
const Footer = loadable(() => import('../components/footer-common'))

function isSmartPhone() {
  if (typeof window !== 'undefined') {
    if (window.matchMedia && window.matchMedia('(max-device-width: 559px)').matches) {
      return true;
    } else {
      return false;
    }
  }
}

function GoogleMapsLoader() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  const [isLoaded, setIsLoaded] = useState(false);

  const mapIsReady = () => {
    setIsLoaded(true);
  }
  
  return (
    <div className={"c-wrapper__companymap" + ( isLoaded ? " u-is-googlemap--ready" : "" )} ref={ref}>
      { inView ? <GooglemapView isReady={mapIsReady} /> : null }
    </div>
  )
}

export default ({data}) => (
<div>
  <Headtagmod />
  <div className="l-wrapper__root">
    <div className="l-mainnav">
      <Controller>
        <Scene classToggle="u-is-active" triggerElement="#summary_mission" offset={300} reverse={true} >
          <ul>
            <li key="top">
              <button onClick={() => scrollTo('#top')}>
                <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                <p>トップ</p>
              </button>
            </li>
            <li key="summary_mission">
              <button onClick={() => scrollTo('#summary_mission')}>
                <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                <p>事業概要</p>
              </button>
            </li>
            <li key="summary_company">
              <button onClick={() => scrollTo('#summary_company')}>
                <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                <p>会社概要</p>
              </button>
            </li>
            <li key="ask_us">
              <button onClick={() => scrollTo('#ask_us')}>
                <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                <p>お問い合わせ</p>
              </button>
            </li>
          </ul>
        </Scene>
        {/*<Scene classToggle="u-is-active" triggerElement="#summary_mission" offset={300} reverse={true} >
          <Link className="c-btn-circle" to="/blog/">
            <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 60 60" xmlSpace="preserve"><path className="st0" d="M4.5 31c0-8.4 4.3-16.5 11.7-21.5l.1 10.6S22.5 9.9 29.6 7.8v17.1c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6v-21c-4 .4-9.5 3.3-13.3 7.5l-.1-7.2S1.2 11.3 1.2 31c0 8.3 3.8 16.1 10.3 21.7H5.2c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6h17.4l-5-3.1C9.3 47.9 4.5 39.7 4.5 31" /><path className="st0" d="M46.4 17.5h10.7c.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6H46.4c-6.2 0-9.5 4.1-9.5 8.1 0 2.1.9 4.1 2.5 5.5 1.7 1.5 4.2 2.3 7 2.3 5.1 0 8.4 1.8 8.4 4.7 0 1.3-.7 2.7-2 3.8-1.6 1.3-3.8 2-6.3 2H32.6v-7.8c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6v21.8c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6V43.8h13.9c7.3 0 11.7-4.6 11.7-9 0-2.3-1.1-4.3-3.1-5.7-2.1-1.5-5-2.3-8.6-2.3-2 0-3.7-.5-4.8-1.5-.9-.8-1.4-1.9-1.4-3-.1-2.3 1.9-4.8 6.1-4.8M10.5 25.2c-.8 0-1.5-.7-1.5-1.5v-1.2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1.2c0 .8-.7 1.5-1.5 1.5M18.4 25c-.8 0-1.5-.7-1.5-1.5v-1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5M16.6 33.1c-1.4 0-2.3-.6-2.9-1.3-.5.6-1.3 1.1-2.4 1.1-.8 0-1.5-.3-2.1-.8-1-1.1-.9-2.8-.9-2.9 0-.5.5-.9 1-.9s.9.5.9 1c0 .3 0 1.2.4 1.6.1.1.3.3.7.3 1.3 0 1.4-1.3 1.4-1.6 0-.5.4-.9.9-.9s.9.3 1 .8c.1.3.4 2 2.3 1.9 1-.1 1.3-.5 1.4-.6.6-.9.1-2.6-.1-3.1s.1-1 .5-1.2c.5-.2 1 .1 1.2.5.1.3 1.1 3-.1 4.8-.4.6-1.2 1.4-2.8 1.5-.1-.2-.3-.2-.4-.2" /></svg>
            <span>ブログ</span>
          </Link>
</Scene>*/}
      </Controller>
    </div>
    <div className="l-header">
      <h1 className="c-header__root">
      <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 60 60" xmlSpace="preserve"><path className="st0" d="M4.5 31c0-8.4 4.3-16.5 11.7-21.5l.1 10.6S22.5 9.9 29.6 7.8v17.1c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6v-21c-4 .4-9.5 3.3-13.3 7.5l-.1-7.2S1.2 11.3 1.2 31c0 8.3 3.8 16.1 10.3 21.7H5.2c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6h17.4l-5-3.1C9.3 47.9 4.5 39.7 4.5 31" /><path className="st0" d="M46.4 17.5h10.7c.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6H46.4c-6.2 0-9.5 4.1-9.5 8.1 0 2.1.9 4.1 2.5 5.5 1.7 1.5 4.2 2.3 7 2.3 5.1 0 8.4 1.8 8.4 4.7 0 1.3-.7 2.7-2 3.8-1.6 1.3-3.8 2-6.3 2H32.6v-7.8c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6v21.8c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6V43.8h13.9c7.3 0 11.7-4.6 11.7-9 0-2.3-1.1-4.3-3.1-5.7-2.1-1.5-5-2.3-8.6-2.3-2 0-3.7-.5-4.8-1.5-.9-.8-1.4-1.9-1.4-3-.1-2.3 1.9-4.8 6.1-4.8M10.5 25.2c-.8 0-1.5-.7-1.5-1.5v-1.2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1.2c0 .8-.7 1.5-1.5 1.5M18.4 25c-.8 0-1.5-.7-1.5-1.5v-1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5M16.6 33.1c-1.4 0-2.3-.6-2.9-1.3-.5.6-1.3 1.1-2.4 1.1-.8 0-1.5-.3-2.1-.8-1-1.1-.9-2.8-.9-2.9 0-.5.5-.9 1-.9s.9.5.9 1c0 .3 0 1.2.4 1.6.1.1.3.3.7.3 1.3 0 1.4-1.3 1.4-1.6 0-.5.4-.9.9-.9s.9.3 1 .8c.1.3.4 2 2.3 1.9 1-.1 1.3-.5 1.4-.6.6-.9.1-2.6-.1-3.1s.1-1 .5-1.2c.5-.2 1 .1 1.2.5.1.3 1.1 3-.1 4.8-.4.6-1.2 1.4-2.8 1.5-.1-.2-.3-.2-.4-.2" /></svg>
        <span>美園ITサービス合同会社</span>
      </h1>
    </div>
    <div className="l-maincontents">
      <Div100vh id="top" className="l-firstview u-view_single">
        <div className="u-inner">
          <h1 className="c-header__root">
            <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 60 60" xmlSpace="preserve"><path className="st0" d="M4.5 31c0-8.4 4.3-16.5 11.7-21.5l.1 10.6S22.5 9.9 29.6 7.8v17.1c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6v-21c-4 .4-9.5 3.3-13.3 7.5l-.1-7.2S1.2 11.3 1.2 31c0 8.3 3.8 16.1 10.3 21.7H5.2c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6h17.4l-5-3.1C9.3 47.9 4.5 39.7 4.5 31" /><path className="st0" d="M46.4 17.5h10.7c.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6H46.4c-6.2 0-9.5 4.1-9.5 8.1 0 2.1.9 4.1 2.5 5.5 1.7 1.5 4.2 2.3 7 2.3 5.1 0 8.4 1.8 8.4 4.7 0 1.3-.7 2.7-2 3.8-1.6 1.3-3.8 2-6.3 2H32.6v-7.8c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6v21.8c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6V43.8h13.9c7.3 0 11.7-4.6 11.7-9 0-2.3-1.1-4.3-3.1-5.7-2.1-1.5-5-2.3-8.6-2.3-2 0-3.7-.5-4.8-1.5-.9-.8-1.4-1.9-1.4-3-.1-2.3 1.9-4.8 6.1-4.8M10.5 25.2c-.8 0-1.5-.7-1.5-1.5v-1.2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1.2c0 .8-.7 1.5-1.5 1.5M18.4 25c-.8 0-1.5-.7-1.5-1.5v-1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5M16.6 33.1c-1.4 0-2.3-.6-2.9-1.3-.5.6-1.3 1.1-2.4 1.1-.8 0-1.5-.3-2.1-.8-1-1.1-.9-2.8-.9-2.9 0-.5.5-.9 1-.9s.9.5.9 1c0 .3 0 1.2.4 1.6.1.1.3.3.7.3 1.3 0 1.4-1.3 1.4-1.6 0-.5.4-.9.9-.9s.9.3 1 .8c.1.3.4 2 2.3 1.9 1-.1 1.3-.5 1.4-.6.6-.9.1-2.6-.1-3.1s.1-1 .5-1.2c.5-.2 1 .1 1.2.5.1.3 1.1 3-.1 4.8-.4.6-1.2 1.4-2.8 1.5-.1-.2-.3-.2-.4-.2" /></svg>
            <span>美園ITサービス合同会社</span>
          </h1>
          <nav className="p-wrapper-buttons">
            <ul>
              <li key="btn-summary_mission">
                <button className="c-btn-circle--sm" onClick={() => scrollTo('#summary_mission')}>
                  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                  事業概要
                </button>
              </li>
              <li key="btn-summary_company">
                <button className="c-btn-circle--sm" onClick={() => scrollTo('#summary_company')}>
                  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                  会社概要
                </button>
              </li>
              <li key="btn-ask_us">
                <button className="c-btn-circle--sm" onClick={() => scrollTo('#ask_us')}>
                  <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 30 30" xmlSpace="preserve"><path className="st0" d="M8.2 11.7c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7V9c0 1.5-1.2 2.7-2.7 2.7M22.2 11.4c-1.5 0-2.7-1.2-2.7-2.7V6.9c0-1.5 1.2-2.7 2.7-2.7 1.5 0 2.7 1.2 2.7 2.7v1.8c0 1.5-1.2 2.7-2.7 2.7M19.1 25.8c-2.4 0-4.1-1-5.2-2.3-.9 1.1-2.3 1.9-4.3 1.9-1.5 0-2.7-.5-3.6-1.5C4.2 22 4.3 19 4.3 18.7c.1-.9.8-1.6 1.7-1.5 1 0 1.7.8 1.6 1.7 0 .5.1 2 .8 2.8.2.2.5.5 1.2.5 2.2 0 2.5-2.3 2.5-2.8.1-.8.7-1.5 1.6-1.5.9-.1 1.6.6 1.7 1.4.1.6.7 3.5 4.1 3.3 1.8-.1 2.3-.9 2.4-1.1 1-1.6.2-4.6-.2-5.5-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9.2.5 1.9 5.3-.1 8.5-.7 1.1-2.1 2.4-5 2.6-.1-.1-.3-.1-.5-.1" /></svg>
                  お問い合わせ
                </button>
              </li>
            </ul>
            {/*<Link className="c-btn-circle" to="/blog/">
              <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 60 60" xmlSpace="preserve"><path className="st0" d="M4.5 31c0-8.4 4.3-16.5 11.7-21.5l.1 10.6S22.5 9.9 29.6 7.8v17.1c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6v-21c-4 .4-9.5 3.3-13.3 7.5l-.1-7.2S1.2 11.3 1.2 31c0 8.3 3.8 16.1 10.3 21.7H5.2c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6h17.4l-5-3.1C9.3 47.9 4.5 39.7 4.5 31" /><path className="st0" d="M46.4 17.5h10.7c.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6H46.4c-6.2 0-9.5 4.1-9.5 8.1 0 2.1.9 4.1 2.5 5.5 1.7 1.5 4.2 2.3 7 2.3 5.1 0 8.4 1.8 8.4 4.7 0 1.3-.7 2.7-2 3.8-1.6 1.3-3.8 2-6.3 2H32.6v-7.8c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6v21.8c0 .9.7 1.6 1.6 1.6s1.6-.7 1.6-1.6V43.8h13.9c7.3 0 11.7-4.6 11.7-9 0-2.3-1.1-4.3-3.1-5.7-2.1-1.5-5-2.3-8.6-2.3-2 0-3.7-.5-4.8-1.5-.9-.8-1.4-1.9-1.4-3-.1-2.3 1.9-4.8 6.1-4.8M10.5 25.2c-.8 0-1.5-.7-1.5-1.5v-1.2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1.2c0 .8-.7 1.5-1.5 1.5M18.4 25c-.8 0-1.5-.7-1.5-1.5v-1c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5M16.6 33.1c-1.4 0-2.3-.6-2.9-1.3-.5.6-1.3 1.1-2.4 1.1-.8 0-1.5-.3-2.1-.8-1-1.1-.9-2.8-.9-2.9 0-.5.5-.9 1-.9s.9.5.9 1c0 .3 0 1.2.4 1.6.1.1.3.3.7.3 1.3 0 1.4-1.3 1.4-1.6 0-.5.4-.9.9-.9s.9.3 1 .8c.1.3.4 2 2.3 1.9 1-.1 1.3-.5 1.4-.6.6-.9.1-2.6-.1-3.1s.1-1 .5-1.2c.5-.2 1 .1 1.2.5.1.3 1.1 3-.1 4.8-.4.6-1.2 1.4-2.8 1.5-.1-.2-.3-.2-.4-.2" /></svg>
              <span>ブログ</span>
</Link>*/}
          </nav>
        </div>
        { !isSmartPhone() ? <BackgroundSlider /> : <Img className="l-background__firstview" fluid={data.file.childImageSharp.fluid} style={{ position: "absolute" }} alt="" /> }
        {/* { !isSmartPhone() ? <BackgroundSlider /> : null } */}
      </Div100vh>
      <section id="summary_mission" className="l-summary_mission u-view_single">
        <Controller>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <h2 className="c-header__section">事業概要</h2>
          </Scene>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <div className="p-section_body">
              <h4 className="c-header__topic">「"ちょっと"何とかしたい」を<br className="u-sp-only" />ITで解決する会社です</h4>
              <p>美園ITサービス合同会社は、皆様の業務において発生する<br />「ちょっと何とかしたい」を、ITで解決するためのお手伝いを致します。<br />皆様の身近な課題意識に寄り添いながら、一緒にビジネスを成功に導ければ幸いです。</p>
            </div>
          </Scene>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <ul className="p-works_description">
              <li key="NetworkWired">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faNetworkWired} /></span>
                <p>内部統制対応のための<br />社内IT環境の構築</p>
              </li>
              <li key="ChartBar">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faChartBar} /></span>
                <p>小売店向けデータウェアハウスの<br />設計・開発・運用</p>
              </li>
              <li key="Pallet">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faPallet} /></span>
                <p>ワークフローシステムの<br />設計・開発・運用</p>
              </li>
              <li key="Server">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faServer} /></span>
                <p>システム・インフラ<br />設計コンサルティング</p>
              </li>
              <li key="Agile">
                <span className="c-works_description__icon">
                <svg version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 50 50" xmlSpace="preserve"><path className="st0" d="M13.2 26.8l-3.3 3.5c.3.5.7 1 1.1 1.4h7.5c-2.2-1-4.1-2.8-5.3-4.9zM10.9 26.9l4.1-4.3c.8-.9.5-1.6-.7-1.6h-2.6c0-4.5 2.5-8.4 6.1-10.4l-3.5-3.3C9.9 10.2 7 15.2 7 21H4.4c-1.2 0-1.5.7-.7 1.6l4.1 4.3c.9.9 2.2.9 3.1 0zM46.1 33.8l-4.3-4.1c-.9-.8-1.6-.5-1.6.7V33h-2.6c-2 2.2-4.4 3.8-7.3 4.7h9.9v2.6c0 1.2.7 1.5 1.6.7l4.3-4.1c.9-.9.9-2.3 0-3.1z" /><path className="st0" d="M35.1 40.3c-.2-1.2-1-1.4-1.7-.4L31.9 42l-6.2-4.5c4.7-.6 8.8-3.2 11.5-6.8.2-.3.5-.7.7-1 0-.1.1-.2.1-.2.2-.3.4-.7.5-1 .1-.1.1-.2.2-.4.1-.3.3-.6.4-1 .1-.2.1-.3.2-.5l.3-.9c.1-.2.1-.4.2-.6.1-.3.1-.6.2-.9 0-.2.1-.5.1-.7 0-.3.1-.6.1-.8v-.9-.6h2.6c1.2 0 1.5-.7.7-1.6l-4-4.6c-.8-.9-2.2-.9-3.1 0l-4.1 4.3c-.8.9-.5 1.7.7 1.7h2.6v1.5c0 .2-.1.4-.1.6 0 .1-.1.3-.1.4-.1.3-.1.6-.2.9-.1.2-.1.3-.2.5-.1.1-.1.3-.2.4-.1.2-.1.4-.2.5 0 .1-.1.2-.1.3-.3.7-.7 1.3-1.1 1.9 0 0 0 .1-.1.1-1.2 1.5-2.7 2.8-4.4 3.6-1.5.7-3.3 1.2-5.1 1.2h-19v4.7H18l11.2 8.2-1.5 2.1c-.7 1-.3 1.7.9 1.5l5.9-.7c1.2-.2 2-1.3 1.8-2.5l-1.2-5.9zM22 12.3c.9.8 1.6.5 1.6-.7V9c4.5 0 8.4 2.5 10.4 6.1l3.3-3.5c-3-4.4-8.1-7.3-13.8-7.3V1.7c0-1.2-.7-1.5-1.6-.7l-4.3 4.1c-.9.8-.9 2.2 0 3.1l4.4 4.1z" /></svg>
                </span>
                <p>アジャイル開発プロセス<br />コンサルティング</p>
              </li>
              <li key="FlagCheckered">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faFlagCheckered} /></span>
                <p>システムの品質評価および<br />品質向上支援</p>
              </li>
              <li key="Satellite">
                <span className="c-works_description__icon"><FontAwesomeIcon icon={faSatellite} /></span>
                <p>インターネット回線<br />携帯電話回線取次</p>
              </li>
            </ul>
          </Scene>
        </Controller>
      </section>
      <section id="summary_company" className="l-summary_company u-view_single">
        <Controller>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <h2 className="c-header__section">会社概要</h2>
          </Scene>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
          <div className="p-section_body">
            <dl className="p-summary_company">
              <dt>会社名</dt>
              <dd>美園ITサービス合同会社</dd>
              <dt>代表社員</dt>
              <dd>和田洋樹 <br className="u-sp-only" />(認定スクラムマスター)</dd>
              <dt>設立日</dt>
              <dd>2014年9月4日</dd>
              <dt>資本金</dt>
              <dd>200万円</dd>
            </dl>
          </div>
          </Scene>
          <Scene classToggle="u-is-active" triggerHook={0.9} reverse={true}>
            <div className="p-section_body">
              <h4 className="c-header__topic">東京オフィス</h4>
              <p>〒101-0041<br />東京都千代田区神田須田町1-5 ヤマヨビル6階</p>
            </div>
          </Scene>
          <GoogleMapsLoader />
        </Controller>
      </section>
      <section id="ask_us" className="l-ask_us u-view_single">
        <Controller>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <h2 className="c-header__section">お問い合わせ</h2>
          </Scene>
          <Scene classToggle="u-is-active" triggerHook={0.75} reverse={true}>
            <div className="p-section_body">
              <FormToCompany />
            </div>
          </Scene>
        </Controller>
      </section>
    </div>
    <Footer />
  </div>
</div>
)

export const query = graphql`
  query  {
    file(relativePath: {eq: "slideshowimage_050.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 75){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
